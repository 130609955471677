@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


/* Removes visable scrollbars but functionality remains */
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 90%;
  height:8mm ;
  font-size: 18px;
  background: "black";
  border: "1px solid #000!important";
  }

  .pdf-spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 90%;
    height:8mm ;
    font-size: 18px;
    background: "black";
    border: "1px solid #000!important";
    }
  
  @keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    50% { transform: translateX(4px); }
    75% { transform: translateX(-4px); }
    100% { transform: translateX(0); }
  }
  
  .shake {
    animation: shake 0.5s ease-in-out;
  }
  