.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 20%;
    }
    
    @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }

    .logo-loader{
        animation-name: pulse;
        animation-duration: 500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        width: 20%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
        }
    
        70% {
            transform: scale(1);
        }
    
        100% {
            transform: scale(0.95);
        }
    }